
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiOutlinedInput-root{
  border-radius: 0px !important;
}
.MuiSvgIcon-root{
  font-size: 20px,
}
.MuiLoadingButton-root {
  border-radius: 0px !important;
}
.MuiFormControlLabel-label{
  font-size:10px
}
.swiper-slide:first-child {
  margin-left: 10px;
}
.MuiSelect-select .MuiSelect-outlined{
  font-size: '12px' !important;
}
.filter-slider{
  width: auto !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
